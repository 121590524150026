import { makeStyles, Typography } from "@material-ui/core"
import { graphql, PageProps } from "gatsby"
import React from "react"
import { Helmet } from "react-helmet"

import { Layout } from "../components/Layout"
import { Link } from "../components/Link"
import { PageContext } from "../interfaces/pageContext"

const useStyles = makeStyles({
  container: {
    counterReset: "h2",

    "& a": {
      textDecoration: "underline",
    },

    "& h2": {
      counterReset: "h3",
    },

    "& h2::before": {
      content: `counter(h2, upper-roman) ". "`,
      counterIncrement: "h2",
    },

    "& h3::before": {
      content: `counter(h3) ". "`,
      counterIncrement: "h3",
    },
  },
})

export type PrivacyPageProps = PageProps<any, PageContext>

const PrivacyPage: React.FC<PrivacyPageProps> = (props) => {
  const classes = useStyles(props)
  const { data, location, pageContext } = props
  pageContext.title = "Datenschutz"
  return (
    <Layout location={location} pageContext={pageContext} showGdprModal={false}>
      <Helmet defer={false}>
        <meta name="robots" content={`noindex, noarchive, noimageindex`} />
      </Helmet>
      <div className={classes.container} id="container">
        <Typography align="center" variant="h1">
          Datenschutzerklärung
        </Typography>
        <section>
          <Typography align="center" variant="h2">
            Kontakt
          </Typography>
          <Typography paragraph={true}>
            Andreas Donig buybags.de (im Folgenden: „buybags.de“)
            <br />
            Bräugasse 10
            <br />
            94032 Passau
            <br />
            Telefon: <Link href={`tel:0157-33877653`}>0157-33877653</Link>
            <br />
            E-Mail: <Link href={`mailto:hello@buybags.de`}>hello@buybags.de</Link>
            <br />
            Internet: <Link href={`/`}>{data.site.siteMetadata.siteUrl}</Link>
            <br />
          </Typography>
          <Typography paragraph={true}>
            Sie können uns in allen datenschutzrechtlichen Belangen auch eine E-Mail direkt an{" "}
            <Link href={`mailto:privacy@buybags.de`}>privacy@buybags.de</Link> senden.
          </Typography>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Verarbeitung personenbezogener Daten sowie Art und Zweck
          </Typography>
          <Typography align="center" variant="h3">
            Hosting
          </Typography>
          <Typography paragraph={true}>
            Für die Bereitstellung unserer Website und Dienste bedienen wir uns folgender
            spezialisierter Dienstleister, an die wir die erforderlichen Daten übermitteln:
          </Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              Amazon Web Services Germany GmbH, Domagkstraße 28, 80807 München.
            </Typography>
          </ul>
          <Typography paragraph={true}>
            Diese speichern die Website bzw. die Nutzerdaten auf ihren Servern (Hosting). Für das
            Angebot dieser Plattform und unserer Dienste ist die Beauftragung von
            Hosting-Dienstleistern erforderlich. Die Inanspruchnahme erfolgt gemäß{" "}
            <strong>Art. 6 Abs. 1 S. 1 lit. f DSGVO</strong> aufgrund berechtigter wirtschaftlicher
            Interessen, diese Website bereitzuhalten.
          </Typography>
          <Typography paragraph={true}>
            Im Zusammenhang mit dem Hosting werden im Auftrag von buybags.de personenbezogene Daten
            verarbeitet, wenn der Nutzer die Webseite besucht.
          </Typography>
          <Typography paragraph={true}>
            Wir haben mit den Dienstleistern Verträge über die Auftragsverarbeitung für Verarbeitung
            personenbezogener Daten abgeschlossen. Durch diesen Vertrag versichern Amazon Web
            Services, dass sie die Daten im Einklang mit der Datenschutzgrundverordnung verarbeiten
            und den Schutz der Rechte der betroffenen Person gewährleisten. Die Server liegen
            jeweils in Europa. Eine Datenübermittlung in Staaten außerhalb des EWR findet nicht
            statt.
          </Typography>
          <Typography align="center" variant="h3">
            Beim Besuch unserer Website
          </Typography>
          <Typography paragraph={true}>
            Beim Aufrufen unserer Website buybags.de werden durch den auf Ihrem Endgerät zum Einsatz
            kommenden Browser automatisch Informationen an den Server unserer Website gesendet.
            Diese Informationen werden temporär in einem sog. Logfile gespeichert. Folgende
            Informationen werden dabei ohne Ihr Zutun erhoben und gespeichert und nach 30 Tagen aus
            den Logfiles gelöscht:
          </Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              IP-Adresse des anfragenden Rechners
            </Typography>
            <Typography component="li" paragraph={true}>
              Datum und Uhrzeit des Zugriffs
            </Typography>
            <Typography component="li" paragraph={true}>
              URL der abgerufenen Datei
            </Typography>
            <Typography component="li" paragraph={true}>
              Website, von der aus der Zugriff erfolgt (Referrer-URL)
            </Typography>
            <Typography component="li" paragraph={true}>
              Browsertyp und –version
            </Typography>
          </ul>
          <Typography paragraph={true}>
            Die genannten Daten werden durch uns zu folgenden Zwecke verarbeitet:
          </Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              Zur Gewährleistung eines reibungslosen Verbindungsaufbaus der Website
            </Typography>
            <Typography component="li" paragraph={true}>
              Zur Gewährleistung einer komfortablen Nutzung unserer Website
            </Typography>
            <Typography component="li" paragraph={true}>
              Zur Auswertung der Systemsicherheit und -stabilität
            </Typography>
            <Typography component="li" paragraph={true}>
              Zur Erkennung und Verhinderung von Angriffen auf unsere Website
            </Typography>
            <Typography component="li" paragraph={true}>
              Zur kontinuierlichen Verbesserung der Website
            </Typography>
            <Typography component="li" paragraph={true}>
              Zu weiteren statistischen und administrativen Zwecken
            </Typography>
          </ul>
          <Typography paragraph={true}>
            Die Rechtsgrundlage für die Datenverarbeitung ist{" "}
            <strong>Art. 6 Abs. 1 S. 1 lit. f DSGVO</strong>. Unser berechtigtes Interesse folgt aus
            oben aufgelisteten Zwecken der Datenverarbeitung.
          </Typography>
          <Typography paragraph={true}>
            Grundsätzlich verwenden wir die erhobenen Daten nicht, um Rückschlüsse auf ihre Person
            zu ziehen. Im Falle eines Angriffs auf unsere Netzinfrastruktur wird jedoch zur
            Geltendmachung oder Abwehr von Rechtsansprüchen Ihre erhobene IP-Adresse ausgewertet.
            Darüber hinaus setzen wir beim Besuch unserer Website Cookies sowie Analysedienste ein.
            Nähere Erläuterungen dazu erhalten Sie in dieser Datenschutzerklärung.
          </Typography>
          <Typography align="center" variant="h3">
            Im Rahmen unserer Gewinnspiele
          </Typography>
          <Typography paragraph={true}>
            Wenn Sie an einem Gewinnspiel auf unserer Website teilnehmen, erheben wir von Ihnen
            diejenigen Daten, die für die Teilnahme am Gewinnspiel erforderlich sind (z.B. Name,
            Vorname und E-Mail-Adresse), u.a. um festzustellen, ob Sie teilnahmeberechtigt sind
            sowie zur Ermittlung und Benachrichtigung der Gewinner per E-Mail. Stellen Sie uns die
            genannten Daten nicht zur Verfügung ist eine Teilnahme am Gewinnspiel bzw. eine
            Kontaktaufnahme bzgl. einer Gewinnmitteilung nicht möglich. Für den Fall, dass Sie im
            Rahmen der Teilnahme am Gewinnspiel einen Gewinn beziehen, kann es erforderlich sein,
            Ihre Daten an den Partner, der den Preis zur Verfügung stellt weiterzugeben, damit
            dieser Ihnen den Gewinn zukommen lassen kann.
          </Typography>
          <Typography paragraph={true}>
            Die Datenverarbeitung erfolgt auf Ihre Anfrage hin und ist nach{" "}
            <strong>Art. 6 Abs. 1 S. 1 lit. b und lit. f DSGVO</strong> zu den genannten Zwecken für
            die Erfüllung des Vertrages und für sonstige vorvertraglicher Maßnahmen sowie unserer
            berechtigten Interessen an den vorgenannten Zwecken der Verarbeitung, erforderlich.
          </Typography>
          <Typography paragraph={true}>
            Sollten Sie nicht gewonnen haben, werden Ihre ausschließlich im Zusammenhang mit dem
            Gewinnspiel verarbeiteten Daten unverzüglich, spätestens 30 Tage nachdem der Gewinner
            bestätigt wurde, gelöscht.
          </Typography>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Weitergabe von Daten an Dritte
          </Typography>
          <Typography paragraph={true}>
            Insbesondere soweit dies gesetzlich zulässig und nach{" "}
            <strong>Art. 6 Abs. 1 S. 1 lit. b DSGVO</strong> für die Abwicklung von
            Vertragsverhältnissen mit Ihnen erforderlich ist, können Ihre personenbezogenen Daten
            ggf. an Dritte weitergegeben werden, z.B. damit Ihnen, sollten Sie bei einem unserer
            gesponsorten Gewinnspiele teilgenommen und gewonnen haben, der Gewinn übergeben werden
            kann.
          </Typography>
          <Typography align="center" variant="h3">
            Zu weiteren Zwecken
          </Typography>
          <Typography paragraph={true}>
            Darüber hinaus geben wir Ihre persönlichen Daten nur an Dritte weiter, wenn Sie Ihre
            nach <strong>Art. 6 Abs. 1 S. 1 lit. a DSGVO</strong> ausdrückliche Einwilligung dazu
            erteilt haben, für den Fall, dass für die Weitergabe nach{" "}
            <strong>Art. 6 Abs. 1 S. 1 lit. c DSGVO</strong> eine gesetzliche Verpflichtung besteht,
            sowie die Weitergabe nach <strong>Art. 6 Abs. 1 S. 1 lit. f DSGVO</strong> zur
            Geltendmachung, Ausübung oder Verteidigung von Rechtsansprüchen erforderlich ist und
            kein Grund zur Annahme besteht, dass Sie ein überwiegendes schutzwürdiges Interesse an
            der Nichtweitergabe Ihrer Daten haben.
          </Typography>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Cookies
          </Typography>
          <Typography align="center" variant="h3">
            Defintion Cookies
          </Typography>
          <Typography paragraph={true}>
            Wir setzen auf unserer Seite Cookies ein. Hierbei handelt es sich um kleine Dateien, die
            Ihr Browser automatisch erstellt und die auf Ihrem Endgerät (Laptop, Tablet, Smartphone
            o.ä.) gespeichert werden, wenn Sie unsere Seite besuchen. Cookies richten auf Ihrem
            Endgerät keinen Schaden an, enthalten keine Viren, Trojaner oder sonstige Schadsoftware.
          </Typography>
          <Typography paragraph={true}>
            In dem Cookie werden Informationen abgelegt, die sich jeweils im Zusammenhang mit dem
            spezifisch eingesetzten Endgerät ergeben. Dies bedeutet jedoch nicht, dass wir dadurch
            unmittelbar Kenntnis von Ihrer Identität erhalten.
          </Typography>
          <Typography paragraph={true}>
            Der Einsatz von Cookies dient einerseits dazu, die Nutzung unseres Angebots für Sie
            angenehmer zu gestalten. So setzen wir sogenannte Session-Cookies ein, um zu erkennen,
            dass Sie einzelne Seiten unserer Website bereits besucht haben, Sie sich bereits in
            Ihrem persönlichen Profil angemeldet haben oder zu Darstellungszwecken. Diese werden
            nach Verlassen unserer Seite automatisch gelöscht.
          </Typography>
          <Typography paragraph={true}>
            Darüber hinaus setzen wir ebenfalls zur Optimierung der Benutzerfreundlichkeit
            temporäre, sogenannte Comfort-Cookies ein, die für einen bestimmten festgelegten
            Zeitraum auf Ihrem Endgerät gespeichert werden. Besuchen Sie unsere Seite erneut, um
            unsere Dienste in Anspruch zu nehmen, wird automatisch erkannt, dass Sie bereits bei uns
            waren und welche Eingaben und Einstellungen sie getätigt haben, um diese nicht noch
            einmal eingeben zu müssen. In der Regel werden diese unverzüglich, spätestens jedoch
            nach 30 Tagen gelöscht.
          </Typography>
          <Typography paragraph={true}>
            Zum anderen setzten wir im Rahmen von Drittanbieter-Tools Cookies ein, um die Nutzung
            unserer Website statistisch zu erfassen und zum Zwecke der Optimierung unseres Angebotes
            für Sie auszuwerten. Diese Analyse-, Tracking- und Targeting Cookies (wir nutzen
            beispielsweise Criteo und Facebook) ermöglichen es uns, bei einem erneuten Besuch
            unserer Seite automatisch zu erkennen, dass Sie bereits bei uns waren. Diese Cookies
            werden nach einer jeweils definierten Zeit automatisch gelöscht.
          </Typography>
          <Typography align="center" variant="h3">
            Rechtsgrundlage der Verarbeitung mithilfe von Cookies
          </Typography>
          <Typography paragraph={true}>
            Die durch Cookies verarbeiteten Daten sind für die genannten Zwecke zur Wahrung unserer
            berechtigten Interessen an der Analyse, Optimierung und wirtschaftlichen Betriebs
            unseres Angebots sowie der berechtigten Interessen Dritter nach{" "}
            <strong>Art. 6 Abs. 1 S. 1 lit. f DSGVO</strong> erforderlich.
          </Typography>
          <Typography align="center" variant="h3">
            Opt-Out-Möglichkeiten
          </Typography>
          <Typography paragraph={true}>
            Die meisten Browser akzeptieren Cookies und Pixel automatisch. Sie können Ihren Browser
            jedoch so konfigurieren, dass keine Cookies auf Ihrem Computer gespeichert werden oder
            stets ein Hinweis erscheint, bevor ein neues Cookie angelegt wird.
          </Typography>
          <Typography paragraph={true}>
            Die vollständige Deaktivierung von Cookies kann jedoch dazu führen, dass Sie nicht alle
            Funktionen unserer Website nutzen können. Eine Anleitung zur Abschaltung von Cookies in
            Ihrem Browser finden Sie in der Hilfe-Funktion Ihres Browsers oder unter den folgenden
            Links:
          </Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              <Link href="https://support.mozilla.org/de/kb/wie-verhindere-ich-dass-websites-mich-verfolgen">
                Mozilla Firefox
              </Link>
            </Typography>
            <Typography component="li" paragraph={true}>
              <Link href="https://support.google.com/chrome/answer/2790761?hl=de">
                Google Chrome
              </Link>
            </Typography>
            <Typography component="li" paragraph={true}>
              <Link href="https://support.microsoft.com/de-de/help/4468242/microsoft-edge-browsing-data-and-privacy-microsoft-privacy">
                Microsoft Edge
              </Link>
            </Typography>
            <Typography component="li" paragraph={true}>
              <Link href="https://support.apple.com/de-de/guide/safari/sfri40732/mac">
                Apple Safari
              </Link>
            </Typography>
            <Typography component="li" paragraph={true}>
              <Link href="https://support.microsoft.com/de-de/help/17288/windows-internet-explorer-11-use-do-not-track">
                Microsoft Internet Explorer 11
              </Link>
            </Typography>
            <Typography component="li" paragraph={true}>
              <Link href="https://www.drwindows.de/windows-anleitungen-faq/61099-do-not-track-internet-explorer-10-windows.html">
                Microsoft Internet Explorer 10
              </Link>
            </Typography>
            <Typography component="li" paragraph={true}>
              <Link href="https://www.tecchannel.de/a/internet-explorer-9-do-not-track-header-aktivieren,2037920">
                Microsoft Internet Explorer 9
              </Link>
            </Typography>
          </ul>
          <Typography paragraph={true}>
            Weitere Opt-Out-Möglichkeiten finden Sie in den folgenden Informationen über die von uns
            eingesetzten Tracking- und Targeting Maßnahmen.
          </Typography>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Tracking und Targeting
          </Typography>
          <Typography paragraph={true}>
            Die im Folgenden aufgeführten und von uns eingesetzten Tracking- und Targeting-Maßnahmen
            werden auf der Grundlage des <strong>Art. 6 Abs. 1 S. 1 lit. f DSGVO</strong>{" "}
            durchgeführt.
          </Typography>
          <Typography paragraph={true}>
            Mit den zum Einsatz kommenden Tracking-Maßnahmen wollen wir eine bedarfsgerechte
            Gestaltung und die fortlaufende Optimierung unserer Website sicherstellen. Zum anderen
            setzen wir die Tracking-Maßnahmen ein, um die Nutzung unserer Website statistisch zu
            erfassen. Über die zum Einsatz kommenden Targeting-Maßnahmen wollen wir außerdem
            sicherstellen, dass Ihnen nur an Ihren tatsächlichen oder vermeintlichen Interessen
            orientierte Werbung auf Ihren Endgeräten eingeblendet wird.
          </Typography>
          <Typography paragraph={true}>
            Die jeweiligen Datenverarbeitungszwecke und Datenkategorien sind aus der Beschreibung
            der entsprechenden Tracking-Tools zu entnehmen.
          </Typography>
          <Typography align="center" variant="h3">
            Facebook Custom Audiences
          </Typography>
          <Typography paragraph={true}>
            Wir setzen Facebook Website Custom Audiences der Facebook Ireland Limited (4 Grand Canal
            Square, Dublin 2, Irland) ein. Hierbei handelt es sich um einen Marketingdienst von
            Facebook. Dieser ermöglicht es uns, bestimmten Gruppen von pseudonymisierten Besuchern
            unserer Website, die auch Facebook nutzen, individuell abgestimmte und
            interessenbezogene Werbung auf Facebook anzeigen zu lassen.
          </Typography>
          <Typography paragraph={true}>
            Auf unserer Website ist ein Facebook Custom Audience-Pixel-Tag integriert. Hierbei
            handelt es sich um einen Java Script-Code, über den nicht-personenbezogene Daten über
            die Nutzung der Website gespeichert werden. Dazu gehören Ihre IP-Adresse, der genutzte
            Browser sowie Ausgangs- und Zielseite. Diese Informationen werden an Server von Facebook
            in den USA übertragen. Facebook unterliegt dem EU-US Privacy Shield, sodass ein
            angemessenes Datenschutzniveau garantiert wird.
          </Typography>
          <Typography paragraph={true}>
            Dort wird automatisiert abgeglichen, ob Sie einen Facebook-Cookie gespeichert haben.
            Über den Facebook-Cookie wird automatisch ermittelt, ob Sie zu der für uns relevanten
            Zielgruppe gehören. Gehören Sie zu der Zielgruppe, werden Ihnen bei Facebook
            entsprechende Anzeigen von uns angezeigt. Bei diesem Vorgang werden Sie durch den
            Abgleich der Daten weder durch uns noch durch Facebook persönlich identifiziert.
          </Typography>
          <Typography paragraph={true}>
            Sie können der Verwendung des Dienstes Custom Audiences auf der{" "}
            <Link href="https://de-de.facebook.com/ads/website_custom_audiences/">
              Website von Facebook
            </Link>{" "}
            widersprechen. Nach Anmeldung zu Ihrem Facebook-Konto gelangen Sie zu den Einstellungen
            für Facebook-Werbeanzeigen.
          </Typography>
          Nähere Informationen zum Datenschutz bei Facebook finden Sie in der{" "}
          <Link href="https://de-de.facebook.com/privacy/explanation">
            Datenschutzrichtlinie von Facebook.
          </Link>
          <Typography align="center" variant="h3">
            Google Analytics
          </Typography>
          <Typography paragraph={true}>
            Wir nutzen auf unserer Website Google Analytics, einen Webanalysedienst der von Google
            Ireland Limited zur Verfügung gestellt wird, einer nach irischem Recht eingetragenen und
            betriebenen Gesellschaft (Registernummer: 368047) mit Sitz in Gordon House, Barrow
            Street, Dublin 4, Irland (im Folgenden: „Google“). In diesem Zusammenhang werden
            pseudonymisierte Nutzungsprofile erstellt und Cookies verwendet. Die durch das Cookie
            erzeugten Informationen über Ihre Benutzung dieser Website wie
          </Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              Browser-Typ/-Version
            </Typography>
            <Typography component="li" paragraph={true}>
              verwendetes Betriebssystem
            </Typography>
            <Typography component="li" paragraph={true}>
              Referrer-URL (die zuvor besuchte Seite)
            </Typography>
            <Typography component="li" paragraph={true}>
              Hostname des zugreifenden Rechners (IP-Adresse)
            </Typography>
            <Typography component="li" paragraph={true}>
              Uhrzeit der Serveranfragen
            </Typography>
          </ul>
          <Typography paragraph={true}>
            werden an einen Server von Google in den USA übertragen und dort gespeichert. Google
            beachtet die Datenschutzbestimmungen des „US-Privacy-Shield“ und ist beim „US-Privacy
            Shield“-Programm des US-Handelsministeriums registriert.
          </Typography>
          <Typography paragraph={true}>
            Zusätzlich haben wir über den Einsatz einen Auftragsverarbeitungsvertrag für die
            Verwendung von Google Analytics abgeschlossen. Durch diesen Vertrag versichert Google,
            dass sie die Daten im Einklang mit der Datenschutzgrundverordnung verarbeiten und den
            Schutz der Rechte der betroffenen Person gewährleisten.
          </Typography>
          <Typography paragraph={true}>
            Die Informationen werden verwendet, um die Nutzung der Website auszuwerten, um Reports
            über die Websiteaktivitäten zusammenzustellen und um weitere mit der Websitenutzung und
            der Internetnutzung verbundene Dienstleistungen zu Zwecken der Marktforschung und
            bedarfsgerechten Gestaltung dieser Internetseiten zu erbringen.
          </Typography>
          <Typography paragraph={true}>
            Auch werden diese Informationen gegebenenfalls an Dritte übertragen, sofern dies
            gesetzlich vorgeschrieben ist oder soweit Dritte diese Daten im Auftrag verarbeiten. Es
            wird in keinem Fall Ihre IP-Adresse mit anderen Daten von Google zusammengeführt. Die
            IP-Adressen werden anonymisiert, so dass eine Zuordnung nicht möglich ist (IP-Masking).
          </Typography>
          <Typography paragraph={true}>
            Alternativ zum{" "}
            <Link href="https://tools.google.com/dlpage/gaoptout?hl=de">Browser-Add-on</Link>,
            insbesondere bei Browsern auf mobilen Endgeräten, können Sie die Erfassung durch Google
            Analytics zudem verhindern, indem Sie auf diesen{" "}
            <Link href="javascript:gaOptout()">Link</Link> klicken. Es wird ein Opt-out-Cookie
            gesetzt, das die zukünftige Erfassung Ihrer Daten beim Besuch dieser Website verhindert.
            Der Opt-out-Cookie gilt nur in diesem Browser und nur für unsere Website und wird auf
            Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem Browser, müssen Sie das
            Opt-out-Cookie erneut setzen.
          </Typography>
          <Typography paragraph={true}>
            Weitere Informationen zum Datenschutz im Zusammenhang mit Google Analytics finden Sie
            etwa in der{" "}
            <Link href="https://support.google.com/analytics/answer/6004245?hl=de">
              Google Analytics-Hilfe
            </Link>
            .
          </Typography>
          <Typography align="center" variant="h3">
            Google Doubleclick
          </Typography>
          <Typography paragraph={true}>
            Auf unserer Website werden unter Verwendung von Cookies Informationen zur Optimierung
            von Werbeeinblendungen erfasst und ausgewertet. Hierfür verwenden wir
            Targeting-Technologien die von Google Ireland Limited („Google“) zur Verfügung gestellt
            wird, einer nach irischem Recht eingetragenen und betriebenen Gesellschaft
            (Registernummer: 368047) mit Sitz in Gordon House, Barrow Street, Dublin 4, Irland
            (Double Click, Double Click Exchange Buyer, Double Click Bid Manager). Diese
            Technologien ermöglichen es uns, Sie gezielt mit individuell interessenbezogener Werbung
            anzusprechen. Die eingesetzten Cookies erfassen zum Beispiel Angaben dazu, für welche
            unserer Produkte Sie sich interessiert haben. Anhand der Informationen können wir Ihnen
            auch auf Seiten Dritter Angebote anzeigen, die speziell an Ihren Interessen ausgerichtet
            sind, wie diese sich aus Ihrem bisherigen Nutzerverhalten ergeben. Die Erfassung und
            Auswertung Ihres Nutzerverhaltens erfolgt ausschließlich pseudonym und ermöglicht es uns
            nicht, Sie zu identifizieren. Insbesondere werden die Informationen nicht mit
            personenbezogenen Daten von Ihnen zusammengeführt.
          </Typography>
          <Typography paragraph={true}>
            Google unterliegt dem EU-US Privacy Shield, sodass ein angemessenes Datenschutzniveau
            garantiert wird.
          </Typography>
          <Typography paragraph={true}>
            Das Cookie wird nach 30 Tagen automatisch gelöscht.
          </Typography>
          <Typography paragraph={true}>
            Wenn Sie keine interessenbezogene Werbung erhalten möchten, können Sie diese Einstellung{" "}
            <Link href="https://www.google.com/ads/preferences/html/opt-out.html">hier</Link>{" "}
            vornehmen.
          </Typography>
          <Typography paragraph={true}>
            Weitergehende Informationen und die Datenschutzbestimmungen bezüglich Werbung und Google
            finden Sie in der Datenschutzerklärung und Nutzungsbedingungen von Google.
          </Typography>
          <Typography align="center" variant="h3">
            Hotjar
          </Typography>
          <Typography paragraph={true}>
            Wir setzen den Analysedienst Hotjar der Hotjar Ltd. St Julian&apos;s Business Centre 3,
            Elia Zammit Street, St Julian&apos;s STJ 1000, Malta, Europe (im Folgenden: „Hotjar“)
            ein. Hotjar ist ein Werkzeug zur Analyse von Nutzerverhalten. Mittels Hotjar können wir
            das Verhalten der Besucher unserer Website wie beispielsweise Mausbewegungen, Klicks und
            Scrollhöhe messen, auswerten und nachverfolgen.
          </Typography>
          <Typography paragraph={true}>
            Zu diesem Zweck setzt Hotjar unter anderem Cookies auf Endgeräten der Seitenbesucher ein
            und kann Daten von Seitenbesuchern wie z. B. Browser Informationen, Betriebssystem,
            Verweildauer auf der Seite etc. in anonymisierter Form speichern.
          </Typography>
          <Typography paragraph={true}>
            Wir haben mit Hotjar einen Auftragsverarbeitungsvertrag abgeschlossen. Durch diesen
            Vertrag versichert Hotjar, dass sie die Daten im Einklang mit der
            Datenschutzgrundverordnung verarbeiten und den Schutz der Rechte der betroffenen Person
            gewährleisten.
          </Typography>
          <Typography paragraph={true}>
            Sie können der Datenverarbeitung durch Hotjar widersprechen, indem Sie unter diesem{" "}
            <Link href="https://www.hotjar.com/opt-out">Link</Link> auf die Schaltfläche „Hotjar
            Deaktivieren“ klicken. Hierdurch wird ein Opt-Out-Cookie gesetzt, der bewirkt, dass
            zukünftig keine Daten von Hotjar verarbeitet werden. Der Opt-out-Cookie gilt nur in
            diesem Browser und wird auf Ihrem Gerät abgelegt. Löschen Sie die Cookies in diesem
            Browser, müssen Sie das Opt-out-Cookie erneut setzen.
          </Typography>
          <Typography paragraph={true}>
            Nähere Informationen zum Datenschutz bei Hotjar finden Sie in deren{" "}
            <Link href="https://www.hotjar.com/legal/policies/privacy">Datenschutzrichtlinie.</Link>
          </Typography>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Social Media Plug-ins
          </Typography>
          <Typography paragraph={true}>
            Unser Angebot verwendet Funktionen verschiedener sozialer Netzwerke („Plug-ins“). Wenn
            Sie eine Website unseres Anegbots aufrufen, die solche Plug-ins verwendet, verbindet
            sich Ihr Browser mit Servern der Anbieter der Plug-ins, ggf. in den USA. Der Inhalt des
            Plug-ins wird direkt von den Anbietern der Plug-ins an Ihren Browser übermittelt und in
            die Seite eingebunden. Wir haben keinen Einfluss auf den Umfang der Daten, die die
            Anbieter über die Plug-ins erheben. Nach den Darstellungen der Plattformen werden
            personenbezogene Daten sowohl bei Nicht-Mitgliedern, bei eingeloggten als auch bei nicht
            eingeloggten Mitgliedern, unter anderem Ihre IP-Adresse, erhoben, verarbeitet und
            übermittelt, unter anderem auf Servern in den USA.
          </Typography>
          <Typography paragraph={true}>
            Wenn Sie bei dem jeweiligen sozialen Netzwerk angemeldet sind, kann dieses den Besuch
            unserer Website Ihrem Profil unmittelbar zuordnen. Wenn Sie mit den Plug-ins
            interagieren (beispielsweise den „Gefällt mir“-Button anklicken oder einen Kommentar
            abgeben), wird diese Information (einschließlich Ihrer IP-Adresse) von Ihrem Browser
            ebenfalls direkt an einen Server der Anbieter der Plug-ins ggf. in die USA übermittelt
            und dort gespeichert.
          </Typography>
          <Typography paragraph={true}>
            Zweck und Umfang der Erhebung, Verarbeitung und Nutzung der Daten durch die Anbieter
            sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zu Ihrem Schutz
            entnehmen Sie den entsprechenden Datenschutzhinweisen von
          </Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              <Link href="https://de-de.facebook.com/about/privacy/">Facebook</Link>
            </Typography>
            <Typography component="li" paragraph={true}>
              <Link href="https://help.instagram.com/519522125107875">Instagram</Link>
            </Typography>
          </ul>
          <Typography paragraph={true}>
            Wenn Sie Mitglied des jeweiligen Netzwerkes sind, aber nicht möchten, dass der Anbieter
            die über unseren Internetauftritt gesammelten Daten mit Ihrem Profil verknüpft, können
            Sie folgende Maßnahmen ergreifen: - Loggen Sie sich stets bei dem jeweiligen Netzwerk
            komplett aus, bevor Sie andere Websites besuchen und löschen Sie evtl. entsprechende
            Cookies; - Blenden Sie die Plug-ins über Ihren Werbeblocker aus. Informationen hierzu
            finden Sie z.B.{" "}
            <Link href="https://www.antispam-ev.de/wiki/Werbeblocker_f%C3%BCr_Browser">hier.</Link>
          </Typography>
        </section>
        <section>
          <Typography align="center" variant="h2">
            Social-Media-Buttons
          </Typography>
          <Typography paragraph={true}>
            Wir setzen auf unserer Website sog. Social-Media-Buttons (auch Social-Media-Plug-ins)
            des sozialen Netzwerks Facebook ein. Hierbei handelt es sich um kleine Schaltflächen
            mittels derer Sie Inhalte unserer Website in sozialen Netzwerken unter Ihrem Profil
            veröffentlichen können.
          </Typography>
          <Typography paragraph={true}>
            Sofern Sie eine solche Schaltfläche aktivieren, wird eine Verbindung zwischen unserer
            Website und dem soziale Netzwerk hergestellt. Neben den betreffenden Inhalten, erhält
            der Betreiber des sozialen Netzwerkes weitere zum Teil personenbezogene Information.
            Dazu gehört etwa der Umstand, dass Sie gerade unsere Seite besuchen.
          </Typography>

          <Typography align="center" variant="h3">
            Facebook Buttons
          </Typography>
          <Typography paragraph={true}>
            Auf unserer Plattform kommen Social-Media Plug-ins der Facebook Ireland Limited, 4 Grand
            Canal Square, Dublin 2, Irland, Europe (Im Folgenden: „Facebook“) zum Einsatz, um deren
            Nutzung persönlicher zu gestalten. Hierfür nutzen wir den „LIKE“ oder “TEILEN”-Button,
            den wir mittels der Shariff-Lösung eingebunden haben.
          </Typography>
          <Typography paragraph={true}>
            Wenn Sie eine Seite unseres Webauftritts aufrufen, wird dabei keine Verbindung mit den
            Servern von Facebook aufgebaut. Wenn Sie auf den entsprechenden Button klicken, werden
            sie auf eine Website von Facebook weitergeleitet. Auf dieser können Sie durch einen
            erneuten Klick unsere Inhalte entweder teilen oder liken. Auf die Verarbeitung von
            personenbezogenen Daten auf Websiten von Dritten haben wir keinen Einfluss.
          </Typography>
          <Typography paragraph={true}>
            Durch den Aufruf der Facebook-Website erhält Facebook die Information, dass Ihr Browser
            die entsprechende Seite aufgerufen hat, auch wenn Sie kein Facebook-Konto besitzen oder
            gerade nicht bei Facebook eingeloggt sind. Diese Information (einschließlich Ihrer
            IP-Adresse) wird von Ihrem Browser direkt an einen Server von Facebook in den USA
            übermittelt und dort gespeichert. Facebook beachtet die Datenschutzbestimmungen des
            „US-Privacy-Shield“ und ist beim „US-Privacy Shield“-Programm des US-Handelsministeriums
            registriert.
          </Typography>
          <Typography paragraph={true}>
            Sind Sie bei Facebook eingeloggt und interagieren mit den Plug-ins auf der
            Facebook-Website, zum Beispiel durch Betätigung des „LIKE“ oder “TEILEN”-Buttons, wird
            die entsprechende Information ebenfalls direkt an einen Server von Facebook übermittelt
            und dort gespeichert. Die Informationen werden zudem auf Facebook veröffentlicht und
            Ihren Facebook-Freunden angezeigt.
          </Typography>
          <Typography paragraph={true}>
            Facebook kann diese Informationen zum Zwecke der Werbung, Marktforschung und
            bedarfsgerechten Gestaltung der Facebook-Seiten benutzen. Hierzu werden von Facebook
            Nutzungs-, Interessen- und Beziehungsprofile erstellt, z. B. um Ihre Nutzung unserer
            Website im Hinblick auf die Ihnen bei Facebook eingeblendeten Werbeanzeigen auszuwerten,
            andere Facebook-Nutzer über Ihre Aktivitäten auf unserer Website zu informieren und um
            weitere mit der Nutzung von Facebook verbundene Dienstleistungen zu erbringen.
          </Typography>
          <Typography paragraph={true}>
            Zweck und Umfang der Datenerhebung und die weitere Verarbeitung und Nutzung der Daten
            durch Facebook sowie Ihre diesbezüglichen Rechte und Einstellungsmöglichkeiten zum
            Schutz Ihrer Privatsphäre entnehmen Sie bitte den Datenrichtlinien von Facebook.
          </Typography>
          <Typography align="center" variant="h3">
            Verwendung von WhatsApp, Pinterest und Facebook
          </Typography>
          <Typography paragraph={true}>
            Wir nutzen auf unserer Webseite Verlinkungen auf die sozialen Netzwerke Pinterest,
            Facebook und WhatsApp, im Folgenden „Anbieter“ genannt.
          </Typography>
          <Typography paragraph={true}>
            <strong>Pinterest</strong> wird betrieben von der Pinterest Inc., 808 Brannan Street,
            San Francisco, CA 94103, USA. Grundsätzlich sind die Verlinkungen durch ein
            Pinterest-Logo erkennbar.
          </Typography>
          <Typography paragraph={true}>
            <strong>Facebook</strong> wird betrieben von der Facebook Inc. 1601 California Ave, Palo
            Alto, CA 94304, USA. Grundsätzlich sind Verlinkungen durch ein Facebook-Logo erkennbar.
          </Typography>
          <Typography paragraph={true}>
            <strong>WhatsApp</strong> ist ein Dienst der WhatsApp Ireland Limited, 4 Grand Canal
            Squ-are, Grand Canal Harbour, Dublin 2, Irland, welches das Tochterunterneh-men der
            WhatsApp Inc., 1601 Willow Road, Menlo Park, California 94025, USA, ist. Grundsätzlich
            sind die Verlinkungen anhand eines WhatsApp-Logos ersichtlich.
          </Typography>
          <Typography paragraph={true}>
            Wir möchten Ihre Daten beim Besuch unserer Webseite besonders schützen. Sofern Sie
            unsere Webseite besuchen, können wir gewährleisten, dass beim Aufruf unserer Webseite
            noch keine Verbindung mit den Servern von Pinterest, Face-book und WhatsApp hergestellt
            wird. Das bedeutet, dass zunächst keine personenbezogenen Daten an die jeweiligen
            Anbieter weitergegeben werden. Den je-weiligen Anbieter erkennen Sie über das Logo.
            Dieses Logo enthält lediglich eine HTTP-Verknüpfung zu der jeweiligen Sharing-Seite von
            Pinterest, Facebook und WhatsApp. Hierbei ist der Link zu dem aktuellen Produkt bereits
            im Text voreinge-fügt. Bei dem Aufruf dieser Seite wird demnach keine direkte Verbindung
            mit den Servern von den o.g. Anbietern hergestellt.
          </Typography>
          <Typography paragraph={true}>
            Falls Sie einen Account (bzw. ein Konto/Profil) des jeweiligen Anbieters besitzen und
            eingeloggt sind, so wird der Besuch unserer Webseite Ihrem Account zugeord-net. Wenn Sie
            den „Pin-it“-, „Gefällt mir“-, „WhatsApp-Sharing“-Button betätigen, wird die Information
            direkt an den Server des jeweiligen Anbieters übermittelt und dort gespeichert.
            Schließlich werden die Informationen auch auf Ihrem Account veröffentlicht und Ihren
            „Kontakten“ dargestellt.
          </Typography>
          <Typography paragraph={true}>
            Die Verarbeitung der Daten erfolgt gem. <strong>Art. 6 I f) DSGVO</strong>. Mit der
            Verwendung der Social-Media Verlinkungen bieten wir Ihnen die Möglichkeit, mit den
            sozialen Netzwerken und anderen Nutzern zu interagieren, sodass wir unser Angebot
            verbessern und für Sie als Nutzer interessanter ausgestalten können.
          </Typography>
          <Typography paragraph={true}>Der/Die Anbieter</Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              Pinterest:{" "}
              <Link href="https://www.privacyshield.gov/participant?id=a2zt00000008VVzAAM&status=Active">
                https://www.privacyshield.gov/participant?id=a2zt00000008VVzAAM&status=Active
              </Link>
            </Typography>
            <Typography component="li" paragraph={true}>
              Facebook:{" "}
              <Link href="https://www.privacyshield.gov/participantid=a2zt0000000GnywAAC&status=Active">
                https://www.privacyshield.gov/participantid=a2zt0000000GnywAAC&status=Active
              </Link>
            </Typography>
            <Typography component="li" paragraph={true}>
              WhatsApp:{" "}
              <Link href="https://www.privacyshield.gov/participant?id=a2zt0000000TSnwAAG&status=Active">
                https://www.privacyshield.gov/participant?id=a2zt0000000TSnwAAG&status=Active
              </Link>
            </Typography>
          </ul>
          <Typography paragraph={true}>
            haben sich zudem dem EU-US Datenschutzschild (EU-US Privacy Shield) unterworfen und
            garantieren, dass die Datenschutzvorgaben der EU bei der Verarbeitung von Daten in den
            USA eingehalten werden.
          </Typography>
          <Typography paragraph={true}>
            Weiterführende Informationen im Hinblick auf die Erhebung und Nutzung von Daten sowie
            Ihre Rechte und Schutzmöglichkeiten finden Sie unter den jeweiligen Datenschutzhinweisen
            von:
          </Typography>
          <ul>
            <Typography component="li" paragraph={true}>
              Pinterest:{" "}
              <Link href="https://policy.pinterest.com/de/privacy-policy">
                https://policy.pinterest.com/de/privacy-policy
              </Link>
            </Typography>
            <Typography component="li" paragraph={true}>
              Facebook:{" "}
              <Link href="https://www.facebook.com/policy.php">
                https://www.facebook.com/policy.php
              </Link>
            </Typography>
            <Typography component="li" paragraph={true}>
              WhatsApp:{" "}
              <Link href="https://www.whatsapp.com/legal/#privacy-policy">
                https://www.whatsapp.com/legal/#privacy-policy
              </Link>
            </Typography>
          </ul>
          <Typography align="center" variant="h3">
            Instagram mit Zwei-Klick-Lösung
          </Typography>
          <Typography paragraph={true}>
            Wir verwenden auf unserer Website aufgrund Ihrer Einwilligung nach{" "}
            <strong>Art. 6 Abs. 1 S. 1 lit. a DSGVO</strong> das Plug-in von Instagram, welches von
            der Instagram LLC., 1601 Willow Road, Menlo Park, CA 94025, USA („Instagram“) betrieben
            wird. Die Plug-ins sind mit einem Instagram-Logo beispielsweise in Form einer
            „Instagram-Kamera“ gekennzeichnet.
          </Typography>
          <Typography paragraph={true}>
            Wenn Sie eine Seite unseres Webauftritts aufrufen, die ein solches Plug-in enthält und
            dieses mittels eines Klicks aktivieren, stellt Ihr Browser eine direkte Verbindung zu
            den Servern von Instagram her. Mittels des Klicks willigen Sie ein, dass der Inhalt des
            Plug-ins von Instagram direkt an Ihren Browser übermittelt und in die Seite eingebunden
            wird. Durch diese Einbindung erhält Instagram die Information, dass Ihr Browser die
            entsprechende Seite unseres Webauftritts aufgerufen hat, auch wenn Sie kein
            Instagram-Profil besitzen oder gerade nicht bei Instagram eingeloggt sind.
          </Typography>
          <Typography paragraph={true}>
            Diese Information (einschließlich Ihrer IP-Adresse) wird von Ihrem Browser direkt an
            einen Server von Instagram in die USA übermittelt und dort gespeichert. Sind Sie bei
            Instagram eingeloggt, kann Instagram den Besuch unserer Website Ihrem Instagram-Account
            unmittelbar zuordnen. Wenn Sie mit den Plug-ins interagieren, zum Beispiel das
            „Instagram“-Button betätigen, wird diese Information ebenfalls direkt an einen Server
            von Instagram übermittelt und dort gespeichert.
          </Typography>
          <Typography paragraph={true}>
            Die Informationen werden außerdem auf Ihrem Instagram-Account veröffentlicht und dort
            Ihren Kontakten angezeigt.
          </Typography>
          <Typography paragraph={true}>
            Wenn Sie nicht möchten, dass Instagram die über unseren Webauftritt gesammelten Daten
            unmittelbar Ihrem Instagram-Account zuordnet, müssen Sie sich vor Ihrem Besuch unserer
            Website bei Instagram ausloggen.
          </Typography>
          <Typography paragraph={true}>
            Weitere Informationen hierzu Sie in der{" "}
            <Link href="https://help.instagram.com/155833707900388">
              Datenschutzerklärung von Instagram.
            </Link>
          </Typography>
        </section>
        <Typography align="center" variant="h2">
          Betroffenenrechte
        </Typography>
        <Typography paragraph={true}>Sie haben das Recht:</Typography>
        <ul>
          <Typography component="li" paragraph={true}>
            gemäß <strong>Art. 7 Abs. 3 DSGVO</strong> Ihre einmal erteilte Einwilligung jederzeit
            gegenüber uns zu widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf
            dieser Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen;
          </Typography>
          <Typography component="li" paragraph={true}>
            gemäß <strong>Art. 15 DSGVO</strong> Auskunft über Ihre von uns verarbeiteten
            personenbezogenen Daten zu verlangen. Insbesondere können Sie Auskunft über die
            Verarbeitungszwecke, die Kategorie der personenbezogenen Daten, die Kategorien von
            Empfängern, gegenüber denen Ihre Daten offengelegt wurden oder werden, die geplante
            Speicherdauer, das Bestehen eines Rechts auf Berichtigung, Löschung, Einschränkung der
            Verarbeitung oder Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft ihrer
            Daten, sofern diese nicht bei uns erhoben wurden, sowie über das Bestehen einer
            automatisierten Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen
            Informationen zu deren Einzelheiten verlangen;
          </Typography>
          <Typography component="li" paragraph={true}>
            gemäß <strong>Art. 16 DSGVO</strong> unverzüglich die Berichtigung unrichtiger oder
            Vervollständigung Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
          </Typography>
          <Typography component="li" paragraph={true}>
            gemäß <strong>Art. 17 DSGVO</strong> die Löschung Ihrer bei uns gespeicherten
            personenbezogenen Daten zu verlangen, soweit nicht die Verarbeitung zur Ausübung des
            Rechts auf freie Meinungsäußerung und Information, zur Erfüllung einer rechtlichen
            Verpflichtung, aus Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung
            oder Verteidigung von Rechtsansprüchen erforderlich ist;
          </Typography>
          <Typography component="li" paragraph={true}>
            gemäß <strong>Art. 18 DSGVO</strong> die Einschränkung der Verarbeitung Ihrer
            personenbezogenen Daten zu verlangen, soweit die Richtigkeit der Daten von Ihnen
            bestritten wird, die Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und
            wir die Daten nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder
            Verteidigung von Rechtsansprüchen benötigen oder Sie gemäß{" "}
            <strong>Art. 21 DSGVO</strong> Widerspruch gegen die Verarbeitung eingelegt haben;
          </Typography>
          <Typography component="li" paragraph={true}>
            gemäß <strong>Art. 20 DSGVO</strong> Ihre personenbezogenen Daten, die Sie uns
            bereitgestellt haben, in einem strukturierten, gängigen und maschinenlesebaren Format zu
            erhalten oder die Übermittlung an einen anderen Verantwortlichen zu verlangen und
          </Typography>
          <Typography component="li" paragraph={true}>
            gemäß <strong>Art. 77 DSGVO</strong> sich bei einer Aufsichtsbehörde zu beschweren. In
            der Regel können Sie sich hierfür an die Aufsichtsbehörde ihres üblichen
            Aufenthaltsortes oder Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
          </Typography>
        </ul>
        <Typography paragraph={true}>
          Zur Geltendmachung ihrer Betroffenenrechte gegenüber uns, wenden Sie sich an{" "}
          <Link href="mailto:privacy@buybags.de">privacy@buybags.de</Link>
        </Typography>

        <Typography align="center" variant="h2">
          Widerspruchsrecht
        </Typography>
        <Typography paragraph={true}>
          Sofern Ihre personenbezogenen Daten auf Grundlage von berechtigten Interessen gemäß{" "}
          <strong>Art. 6 Abs. 1 S. 1 lit. f DSGVO</strong> verarbeitet werden, haben Sie das Recht,
          gemäß <strong>Art. 21 DSGVO</strong> Widerspruch gegen die Verarbeitung Ihrer
          personenbezogenen Daten einzulegen, soweit dafür Gründe vorliegen, die sich aus Ihrer
          besonderen Situation ergeben oder sich der Widerspruch gegen Direktwerbung richtet. Im
          letzteren Fall haben Sie ein generelles Widerspruchsrecht, das ohne Angabe einer
          besonderen Situation von uns umgesetzt wird.
        </Typography>
        <Typography paragraph={true}>
          Möchten Sie von Ihrem Widerspruchsrecht Gebrauch machen, genügt eine E-Mail an{" "}
          <Link href="mailto:privacy@buybags.de">privacy@buybags.de</Link>
        </Typography>

        <Typography align="center" variant="h2">
          Datensicherheit
        </Typography>
        <Typography paragraph={true}>
          Alle von Ihnen persönlich übermittelten Daten werden mit dem allgemein üblichen und
          sicheren Standard TLS (Transport Layer Security) verschlüsselt übertragen. TLS ist ein
          sicherer und erprobter Standard, der z.B. auch beim Onlinebanking Verwendung findet. Sie
          erkennen eine sichere TLS-Verbindung unter anderem an dem angehängten s am http (also
          https://…) in der Adressleiste Ihres Browsers oder am Schloss-Symbol im oberen Bereich
          Ihres Browsers.
        </Typography>
        <Typography paragraph={true}>
          Wir bedienen uns im Übrigen geeigneter technischer und organisatorischer
          Sicherheitsmaßnahmen, um Ihre Daten gegen zufällige oder vorsätzliche Manipulationen,
          teilweisen oder vollständigen Verlust, Zerstörung oder gegen den unbefugten Zugriff
          Dritter zu schützen.
        </Typography>
        <Typography align="center" variant="h2">
          Aktualität und Änderung dieser Datenschutzerklärung
        </Typography>
        <Typography paragraph={true}>
          Diese Datenschutzerklärung ist aktuell gültig und hat den Stand Dezember 2019.
        </Typography>
      </div>
    </Layout>
  )
}

export default PrivacyPage

export const query = graphql`
  query PrivacyPageQuery {
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
